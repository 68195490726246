import Container from "@mui/material/Container";
import React from "react";
import withRoot from "../modules/withRoot";
import Stack from "@mui/material/Stack";

function QualityIndicatorsAndIngredients() {
  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ my: 4 }}>
        <Stack bgcolor="primary.light" padding={2} spacing={2}>
          <img
            src={"/images/ingredients.jpg"}
            alt="Состав"
          />
          <img
            src={"/images/quality-indicators.jpg"}
            alt="Показатели качества"
          />
        </Stack>
      </Container>
    </React.Fragment>
  )
}

export default withRoot(QualityIndicatorsAndIngredients);
