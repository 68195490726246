import React from "react";
import withRoot from "../modules/withRoot";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "../modules/components/Typography";
import Review from "../modules/views/Review";
import api from "../services/reviews";

const reviews = api.get();

function Reviews() {
  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ my: 4 }}>
        <Typography variant="h1" align="center" my={4}>
          Отзывы
        </Typography>
        <Stack spacing={2}>
          {Array.from(reviews.keys()).map((mapKey, index) =>
            <Review key={index} review={{title: mapKey, text: reviews.get(mapKey)!}} />)}
        </Stack>
      </Container>
    </React.Fragment>
  );
}

export default withRoot(Reviews);
