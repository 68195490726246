import React from "react";
import { Outlet } from "react-router-dom";
import AppAppBar from "../modules/views/AppAppBar";
import AppFooter from '../modules/views/AppFooter';
import withRoot from '../modules/withRoot';

const Layout = () => {
  return (
    <>
    <React.Fragment>
      <AppAppBar />
      <Outlet />
      <AppFooter />
    </React.Fragment>
    </>
  )
}

export default withRoot(Layout);
