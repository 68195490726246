import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "../modules/components/Typography";

const backgroundImage = "images/cow-abduction.svg";

class NotFound extends React.Component {
  render() {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh'
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h1">
                Упс... 404!
              </Typography>
              <Typography variant="h6" sx={{ mt: 4 }}>
                Похоже, что инопланетяне утащили страницу, которую вы хотели посетить
              </Typography>
              <Button variant="contained" sx={{ mt: 4 }}>На Главную</Button>
            </Grid>
            <Grid xs={6}>
              <img
                src={backgroundImage}
                alt=""
                width={500}
                height={250}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  }
}

export default NotFound;
