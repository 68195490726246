import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "../modules/components/Typography";
import withRoot from "../modules/withRoot";

function AboutUs() {
  return (
    <React.Fragment>
      <Box sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundImage: 'url(/images/cow-watching.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'lighten',
        bgcolor: '#ffffffe0',
        zIndex: -1
      }}>
      </Box>
      <Container maxWidth="md" sx={{my: 4}}>
        <Typography variant="h1" align="center" my={4}>
          О компании
        </Typography>
        <Stack spacing={2}>
          <Typography align="justify">
            {'Общество с дополнительной ответственностью "Брестнасоспром" основано 24 мая 2007 г. в г. Бресте. Первоначально компания '}
            {'ориентировалась на торговлю насосным и энергетическим оборудованием. Позже было принято решение о создании собственного цеха по '}
            {'производству нестандартного оборудования для сельского хозяйства.'}
          </Typography>
          <Typography align="justify">
            {'В 2011 г. компания налаживает собственное производство совместно с Национальной академией наук Беларуси по выпуску концентрированных '}
            {'кормов для животноводства под торговой маркой "ВИТАСИЛ". Новый уникальный для Беларуси продукт выходит в серийное производство и дает '}
            {'положительные результаты в хозяйствах.'}
          </Typography>
          <Typography align="justify">
            {'Многолетний опыт работы в сельскохозяйственной сфере позволяет нам эффективно внедрять новые технологии и решения.'}
          </Typography>
          <Typography align="justify">
            {'Производство и реализация качественных концентрированных кормов и нестандартного оборудования – ключевой вклад нашей компании в '}
            {'развитие сельского хозяйства Республики Беларусь.'}
          </Typography>
          <Typography align="justify">
            {'Наши основные ориентиры – это постоянное совершенствование качества и ассортимента выпускаемой продукции, а также построение '}
            {'взаимовыгодных и взаимоуважительных отношений со своими партнерами и клиентами.'}
          </Typography>
          <Typography variant="h6">
            Наши цели:
          </Typography>
          <ol>
            <li>
              <Typography align="justify">
                {'Строительство современного комбината по производству высококачественных кормов для сельскохозяйственных животных.'}
              </Typography>
            </li>
            <li>
              <Typography align="justify">
                {'Обеспечение свободного доступа к высококачественным кормам для всех категорий потребителей.'}
              </Typography>
            </li>
            <li>
              <Typography align="justify">
                {'Создание и совершенствование положительного имиджа жизни в деревне.'}
              </Typography>
            </li>
          </ol>
        </Stack>
      </Container>
    </React.Fragment>
  );
}

export default withRoot(AboutUs);
