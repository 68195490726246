import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import MapIcon from '@mui/icons-material/Map';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {Theme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {SxProps} from '@mui/system';
import Typography from '../components/Typography';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '220px',
  px: 5,
};

const gridItem: SxProps<Theme> = {
  display: "inline-block",
  position: "relative",
  width: "100%",
  height: "100%",
  paddingTop: 7,
  bgcolor: '#ffffff70',
  backgroundSize: "100%",
  backgroundBlendMode: 'lighten',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  MozTransition: 'all .5s',
  OTransition: 'all .5s',
  transition: 'all .5s',
  '&:hover': {
    backgroundSize: "120%",
    bgcolor: '#ff33666b'
  },
}

const Item = (props: { backgroundImage: string; href: string; icon: any; title: string; }) => {
  const { backgroundImage, href, icon, title } = props;
  return (
    <Grid item xs={12} md={4} padding={"0 !important"} >
      <Box sx={{
        ...gridItem,
        backgroundImage
      }}>
        <Link href={href} underline="none">
          <Box sx={item}>
            {icon}
            <Typography variant="h6" sx={{my: 5}}>
              {title}
            </Typography>
          </Box>
        </Link>
      </Box>
    </Grid>
  );
}

function ProductValues() {
  return (
    <Box
      id="product-values"
      component="section"
      sx={{display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light'}}
    >
      <Container sx={{mt: 15, mb: 8, display: 'flex', position: 'relative'}}>
        <Box
          component="img"
          src="/images/productCurvyLines.png"
          alt="curvy lines"
          sx={{pointerEvents: 'none', position: 'absolute', top: -180}}
        />
        <Grid container spacing={5} columns={8}>
          <Item
            backgroundImage={'url("/images/about-us.jpg")'}
            href={"/about-us"}
            icon={<InfoIcon sx={{ fontSize: 55 }} />}
            title="О компании"
          />
          <Item 
            backgroundImage={'url("/images/contacts.jpg")'}
            href={"/contacts"}
            icon={<MapIcon sx={{ fontSize: 55 }} />}
            title="Контакты"
          />
          <Item
            backgroundImage={'url("/images/quality.jpg")'}
            href={"/quality-indicators-and-ingredients"}
            icon={<WorkspacePremiumIcon sx={{ fontSize: 55 }} />}
            title="Показатели качества и состав"
          />
          <Item
            backgroundImage={'url("/images/review.jpg")'}
            href={"/reviews"}
            icon={<ThumbUpIcon sx={{ fontSize: 55 }} />}
            title="Отзывы"
          />
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
