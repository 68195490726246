const get = () => new Map([
  ['Главный зоотехник ОАО "Ракитница" Крук И. М.', 'В ОАО "Ракитница" применяли КУВМ "Витасил" для коров и молодняка крупного рогатого скота. Благодаря скармливанию препарата "Витасил" коровам в 2012 году сократилось количество случаев родильного пореза у коров после отела на 10% и задержании последа с45% снизилось до 12%. В результате улучшились показатели по воспроизводству стада (больше получено телят, сократился сервис период). При применении Витасила коровам среднесуточные удои увеличивались на 0,5-1 литр. При скармливании новорожденному молодняку "Витасил", у телят снизилось заболевание желудочно-кишечного тракта, беломышечной болезни, увеличилась сохранность молодняка. После скармливания "Витасил" через несколько недель шерстный покров у телят начинал блестеть, улучшилась поедаемость корма, увеличились среднесуточные привесы на 0,4 кг.'],
  ['Главный ветврач СПК "Заря-Агро" Довгер В. В.', 'В СПК "Заря-Агро" Ивановского района применяли КУВМ "Витасил" для коров и молодняка крупного рогатого скота. Благодаря скармливанию препарата "Витасил" коровам в 2012 году сократилось количество случаев родильного пореза у коров после отела на 20% и задержание последа с 35% снизилось до 8%. При применении "Витасил" коровам среднесуточные удои увеличились на 0,5-14 литр. При скармливании новорожденному молодняку "Витасил" у телят снизилось количество заболеваний желудочно-кишечного тракта, беломышечной болезни, увеличилась сохранность молодняка. После скармливания "Витасил" через несколько недель шерстный покров у телят начинал блестеть, улучшилась поедаемость корма, увеличились среднесуточные привесы на 0,4 кг.'],
  ['Главный зоотехник ЧСУП "Ляховичское-Агро"', 'ЧСУП "Ляховическое-Агро" Ивановского района применяли УВМКК "Витасил" и УВМКК "Витасил-М" для коров и молодняка КРС до 6-ти месяцев в летне-осенний период 2012 года. В течении скармливания препарата коровам сразу почувствовалась положительная отдача на показателях по воспроизводству стада, повысилась на 20% оплодотворяемость от первого осеменения коров находящихся в беспривязном круглогодичном содержании, сократился сервис-период, уменьшилось количество случаев родильного пореза у коров после отела на 12%, а задержание последа с 60% снизилось до 15%. Среднесуточные удои увеличивались на 1,0-1,2 килограмма. При скармливании молодняка препарата "Витасил-М" снизилось заболевание ЖКТ, беломышечной болезни, улучшилась сохранность молодняка, увеличились среднесуточные привесы на 80 - 120 грамм и составили более 900 - 950 грамм, т.е. поедаемость кормов значительно улучшилась.'],
  ['Директор ОАО "За мир" Новиков П.В.', 'ОАО "За мир" Бресткого района в ноябре-декабре 2012 года на группе доращивания 30-180 дней отмечалась следующая клиническая картина: взъерошенность волосяного покрова, в утренние часы на коже животных характерная "испарина" (потливость) снижение аппетита, среднесуточных привесов. Для решения данной проблемы были закуплены лизунцы микро-макро элементами у фирмы Брестнасоспром Витасил М (Брест) и Провими фирмы Алникор (Гродно) лизунцы скармливали в одном телочнике, предварительно разделив животных на две группы. По истечении двух месяцев волосяной покров принял характерный блеск, взъерошенность исчезла, потливость животных не отмечалась в обоих группах. Однако следует отметить, что разница в ценах препаратов существенная: Витасил М в 1,8 раза дешевле, чем Провими, а эффект от применения ни чем не уступает как по поедаемости, так и по конечному результату.'],
  ['Главный зоотехник СПК "Алексеевичи-Агро"', 'Хозяйство применяло препарат "Витасил" начиная с 2012 года. Полученный положительный эффект выразился в увеличении надоев на 0,5 кг в сутки по группе животный, получавших "Витасил", а также в улучшении показателей по воспроизводству стада: уменьшилось количество случаев задержания последов, послеродовых парезов, случаев остеомаляции у коров, снизилось общее число перегулов, случаев мертворождения и абортов на поздних сроках развития по сравнению с предыдущим годом. Кроме того, стали более редкими случаи заболевания коров гнойным пододерматитом. В случае же заболевания, лечение проходило более результативно, что позволило сократить выбраковку коров по причине хромоты. Молодняк, полученный от коров, получавших "Витасил" в сухостойный период, оказался более устойчив к заболеваниям обмена веществ (беломышечная болезнь, эндемический зоб, алиментарная анемия) и заболеваниям желудочно-кишечного тракта (диспепсия, гастроэнтериты). Как результат, все это привело к увеличению среднесуточного привеса молодняка КРС и период доращивания.'],
  ['Главный зоотехник СПК "Радонежский" Силик В.Н.', 'Хозяйство начало применять "Витасил" в 2012 году. Получили положительный эффект. По сравнению с прошлым 2011 годом, надои увеличились на 1 кг, снизились гинекологические заболевания, стало меньше родильных порезов и как результат были получены лучшие показатели по воспроизводству стада. Уменьшились маститы на 40%, эндометриты уменьшилось на 30%. В стойловый период реже стали наблюдаться признаки нарушения обмена веществ. Новорожденный полученный молодняк более жизнеспособный, у него меньше регистрировалось случаев зоба и беломышечной болезни, уменьшилось количество мертворожденных телят и абортов. В итоге можно сделать вывод, что применение "Витасил" экономически выгодно для хозяйства.'],
  ['Главный ветврач ОАО "Агро-сад Рассвет"', 'Дирекция ОАО "Агро-сад Рассвет" информирует, что телятам отстающим в развитии от сверстников применялся перпарат КУВМ "Витасил" для молодняка крупного рогатого скота. После скармливания препарата через 3 недели шерстный покров у телят начал блестеть, улучшилась поедаемость кормов, увеличились среднесуточные привесы от 0,2 до 0,6 кг, снизились заболевания желудочно-кишечного тракта, беломышечной болезни, увеличилась сохранность молодняка. Наиболее высокий результат был достигнут при применении препаратов "Витасил" + "Имовит".'],
]);

export default { get }
