import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutUs from "./pages/AboutUs";
import Contacts from './pages/Contacts';
// import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import Layout from './pages/Layout';
import NotFound from './pages/NotFound';
import QualityIndicatorsAndIngredients from "./pages/QualityIndicatorsAndIngredients";
import Reviews from "./pages/Reviews";
// import SignIn from './pages/SignIn';
// import SignUp from './pages/SignUp';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {/*<Route path="sign-up" element={<SignUp />} />*/}
          {/*<Route path="sign-in" element={<SignIn />} />*/}
          <Route path="contacts" element={<Contacts />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="reviews" element={<Reviews />} />
          {/*<Route path="forgot-password" element={<ForgotPassword />} />*/}
          <Route path="quality-indicators-and-ingredients" element={<QualityIndicatorsAndIngredients />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
