import React from "react";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import Typography from "../modules/components/Typography";
import withRoot from "../modules/withRoot";

const yMapDefaultState = {
  center: [52.064165, 23.71840],
  zoom: 16
}

function Contacts() {
  return (
    <React.Fragment>
      <Box sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundImage: 'url(/images/cow-tongue.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'lighten',
        bgcolor: '#ffffffe0',
        zIndex: -1
      }}>
      </Box>
        <Container
          maxWidth="md"
          sx={{ my: 4 }}
        >
          <Typography variant="h1" align="center">
            Контакты
          </Typography>
          <Stack spacing={4} mt={4}>
            <Typography variant="h6">
              Адреc: г. Брест, ул. Сябровская, 90В
            </Typography>
            <Typography variant="h6">
              Режим работы: понедельник – пятница с 8:30 до 17:30
            </Typography>
            <Typography variant="h5">
              Контактные лица:
            </Typography>
            <Grid container spacing={1} columns={{xs:6, md:12}}>
              <Grid item xs={6} md={1}>
                <ContactPhoneIcon />
              </Grid>
              <Grid item xs={3} md={3}>
                {'Янчук Владимир Геннадьевич'}
              </Grid>
              <Grid item xs={3} md={2}>
                {'директор'}
              </Grid>
              <Grid item xs={3} md={3}>
                <Link href="tel:+375297236931">+375 29 723-69-31</Link>
              </Grid>
              <Grid item xs={3} md={3}>
                <Link href="mailto:brestprom@gmail.com">brestprom@gmail.com</Link>
              </Grid>
            </Grid>
            <Container sx={{ display: 'flex', mx: 2, justifyContent: 'center' }}>
              <YMaps>
                <Map width={804} height={400} defaultState={yMapDefaultState}>
                  <Placemark geometry={[52.064204, 23.718331]} />
                </Map>
              </YMaps>
            </Container>
          </Stack>
        </Container>
    </React.Fragment>
  )
}

export default withRoot(Contacts);
