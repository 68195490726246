import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import React from "react";
import Typography from "../components/Typography";

type ReviewProps = {
  review: {title: string; text: string}
};

const Review = ({review}: ReviewProps) =>
  <Card sx={{ bgcolor: "#f5f5f5" }}>
    <CardContent>
      <Typography variant="h5" color="text.secondary" gutterBottom>
        {review.title}
      </Typography>
      <Typography variant="body2">
        {review.text}
      </Typography>
    </CardContent>
  </Card>
  
export default Review;
